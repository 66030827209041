/* eslint-disable no-underscore-dangle */
// React
import React from 'react';
import { createRoot } from 'react-dom/client';

// Redux
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';

// Redux Thunk for having async action creators
import thunk from 'redux-thunk';

// React Spectrum
import { defaultTheme, Provider as SpectrumProviderV3 } from '@adobe/react-spectrum';

// I18n
import { setLocale, loadTranslations, syncTranslationWithStore } from 'react-redux-i18n';
import { supportedLocales, fallbackLocale } from './i18n/config';
import translations from './i18n/translations';

// Our main App component, and reducers.
import App from './App';
import reducers from './reducers';

// Constants
import { SET_PREVIEW_MODE } from './constants';

// Analytics
//  import Analytics from './components/Analytics';

// aep tracking
import AEPTracking from './util/aepTracking';
// Util
import dispatchWindowEvent from './util/dispatchWindowEvent';
import { getProgramFromUrl } from './util/util';

if (window.location.href.startsWith('https://solutionpartners.adobe.com')) {
  const pathname = window.location.pathname?.replace('/detail/', '/solution/');
  window.location.href = `https://partners.adobe.com${pathname}${window.location.search}`;
} else if (window.location.href.startsWith('https://solutionpartners.stage2.adobe.com')) {
  const pathname = window.location.pathname?.replace('/detail/', '/solution/');
  window.location.href = `https://partner-directory-ui-flex-stage.adobe.io${pathname}${window.location.search}`;
}

// Wire up Redux Dev Tools and create the store from our reducers, wire up thunk middleware.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

// Load translations to Redux, set default locale.
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));

// Check if we are receiving a locale in the query parameter.
const urlParams = new URLSearchParams(window.location.search);
if (urlParams.has('locale')) {
  const paramLocale = urlParams.get('locale');
  const finalLocale = Object.keys(supportedLocales).includes(paramLocale)
    ? supportedLocales[paramLocale]
    : fallbackLocale;
  localStorage.setItem('i18n_lang', finalLocale);
  store.dispatch(setLocale(finalLocale));
} else {
  store.dispatch(setLocale(localStorage.getItem('i18n_lang') || 'en_US'));
}

// Preview mode
if (urlParams.has('preview') && urlParams.get('preview') === 'true') {
  // Dispatch the preview mode action.
  store.dispatch({ type: SET_PREVIEW_MODE, payload: true });
  window.sessionStorage.setItem('mandatorySignIn', true);
} else {
  window.sessionStorage.removeItem('mandatorySignIn');
}

if (getProgramFromUrl() === 'spp') {
  // call  aep tracking script
  AEPTracking.init();
}

const loadGNav = async () => {
  if (!window.footerInitialized) {
    window.footerInitialized = true;
    const { authoringPath } = window.milo.gNav;
    const env = window.milo.gNav.environment || 'stage';
    const privacyId = new URLSearchParams(window.location.search).get(
      'privacyid',
    );
    const locale = 'en';
    const imsClientId = window.directoryConfig.ims.client;
    const redirect = 'off';
    const theme = 'light';
    const unavVersion = '';

    const miloGNavScript = document.createElement('script');
    miloGNavScript.src = window.milo.gNav.jslib;
    miloGNavScript.type = 'module';
    miloGNavScript.async = true;
    document.body.appendChild(miloGNavScript);
    miloGNavScript.onload = () => {
      window.loadNavigation({
        env,
        locale,
        theme,
        authoringPath,
        footer: { authoringPath, privacyId },
        header: {
          imsClientId,
          // unavComponents,
          redirect,
          unavVersion,
        },
      });
    };
  }
};

// IMS Configuration
window.adobeid = {
  client_id: window.directoryConfig.ims.client,
  environment: window.directoryConfig.ims.environment,
  redirect_uri: window.location.href,
  scope: 'AdobeID,openid',
  locale: 'en_US',
  async onReady() {
    loadGNav();
    // Dispatch a window event
    dispatchWindowEvent('adobeIMS:ready');

    // Whether we force the user to sign in because of preview mode.
    const mandatorySignIn = window.sessionStorage.getItem('mandatorySignIn');

    // If not signed in
    if (!window.adobeIMS.isSignedInUser()) {
      // Enforce mandatory sign if if it's requested
      if (mandatorySignIn) {
        window.adobeIMS.signIn();
      }
    } else {
      dispatchWindowEvent('adobeIMS:profileReady');
    }
  },
};

// Load IMS
const imsLib = document.createElement('script');
imsLib.src = window.directoryConfig.ims.jslib;
document.body.appendChild(imsLib);

// Attach listeners to chat links
const attachJarvis = () => {
  const chatLinks = document.querySelectorAll("a[href='#openjarvis']");
  chatLinks.forEach((link) => {
    link.addEventListener('click', (e) => {
      // Prevent click
      e.preventDefault();
      // Open Jarvis chat window.
      window.feds.services.jarvis.openChat();
    });
  });
};

// Scroll Fix
if ('scrollRestoration' in window.history) {
  // Back off, browser, I got this...
  window.history.scrollRestoration = 'manual';
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <SpectrumProviderV3 theme={defaultTheme} colorScheme="light">
      <App />
    </SpectrumProviderV3>
  </Provider>,
);
