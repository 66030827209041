import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { i18nReducer } from 'react-redux-i18n';
import facetsReducer from './facetsReducer';
import filterReducer from './filterReducer';
import partnerDetailReducer from './partnerDetailReducer';
import resultsReducer from './resultsReducer';
import contactFormReducer from './contactFormReducer';

export default combineReducers({
  i18n: i18nReducer,
  facets: facetsReducer,
  filter: filterReducer,
  partnerDetail: partnerDetailReducer,
  results: resultsReducer,
  contactUs: contactFormReducer,
  form: formReducer,
});
