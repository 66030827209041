import {
  SET_IS_OPEN,
  SET_COMPANY_DETAILS,
} from '../constants';

const INITIAL_STATE = {
  isOpen: false,
  companyDetails: {},
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_IS_OPEN: {
      return { ...state, isOpen: payload };
    }
    case SET_COMPANY_DETAILS: {
      return { ...state, companyDetails: payload };
    }
    default:
      return state;
  }
};
