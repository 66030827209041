// React Imports
import React, { Suspense, useEffect } from 'react';

// Routing
import {
  BrowserRouter, Routes, Route,
  Navigate,
} from 'react-router-dom';
import { Loader } from '@apollo/dynamic-form';
import { AnimatePresence } from 'framer-motion';

// Styles
import './styles/App.scss';
import { useDispatch } from 'react-redux';
import { UPDATE_IS_MOBILE } from './constants';

const ListPage = React.lazy(() => import('./pages/List/ListPage'));
const DetailsPage = React.lazy(() => import('./pages/Details/DetailPage'));

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const mql = window.matchMedia('(max-width: 768px)');
    dispatch({
      type: UPDATE_IS_MOBILE,
      payload: { isMobile: mql.matches },
    });
    // Optional cleanup
    const handleResize = () => dispatch({
      type: UPDATE_IS_MOBILE,
      payload: { isMobile: mql.matches },
    });
    mql.addListener(handleResize);
    return () => mql.removeListener(handleResize); // Clean up listener on component unmount
  }, []);
  return (
    <div className="react-spectrum-provider spectrum spectrum--light spectrum--medium">
      <BrowserRouter basename={window.directoryConfig.app.basepath}>
        <Suspense fallback={(<Loader fullScreen />)}>
          <>
            {/* Animated routes (fade in/out for detail and listing page). */}
            <AnimatePresence mode="wait">
              <Routes>
                <Route path="" element={<Navigate replace to="/solution" />} />
                <Route path="/:programType/:id" element={<DetailsPage />} />
                <Route path="/solution" element={<ListPage programType="solution" />} />
                <Route path="/technology" element={<ListPage programType="technology" />} />
              </Routes>
            </AnimatePresence>
          </>
        </Suspense>

      </BrowserRouter>
    </div>
  );
}

export default App;
