import _ from 'lodash';

import {
  LOADING_FACETS,
  LOADED_FACETS,
  SET_FACET_OPTIONS,
  GET_FACET_PREVIEW_MATCHES,
  SET_FACET_PREVIEW_MATCHES,
  SET_APP_INITIALIZED,
  RESET_APP_INITIALIZED,
  SET_PROGRAM_TYPE,
  UPDATE_IS_MOBILE,
} from '../constants';

const INITIAL_STATE = {
  loading: false,
  initialized: false,
  programPath: 'spp',
  isMobile: false,
  location: {
    geos: [],
    countries: [],
  },
  country: {
    options: [],
    previewMatches: null,
    loadingPreviewMatches: false,
  },
  solution: {
    options: [],
    previewMatches: null,
    loadingPreviewMatches: false,
  },
  industry: {
    options: [],
    previewMatches: null,
    loadingPreviewMatches: false,
  },
  level: {
    options: [],
    previewMatches: null,
    loadingPreviewMatches: false,
  },
  initiatives: {
    options: [],
    previewMatches: null,
    loadingPreviewMatches: false,
  },
  /* Commenting this code as part of hiding  the company-size filter from view
  'company-size': {
    options: [
      { name: '1-9 employees', id: '1-9' },
      { name: '10-49 employees', id: '10-49' },
      { name: '50-99 employees', id: '50-99' },
      { name: '100-499 employees', id: '100-499' },
      { name: '500-999 employees', id: '500-999' },
      { name: '1,000-14,999 employees', id: '1000-14999' },
      { name: '15,000+ employees', id: '15000' },
    ],
  }, */
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case LOADING_FACETS: {
      return { ...state, loading: true };
    }
    case LOADED_FACETS: {
      return { ...state, loading: false };
    }
    // Payload: { name: 'level.options', value: [ {}, {} ] }
    // { name: 'location.geos', value: [ {}, {} ] }
    // { name: 'companySize.minValue', value: 50 }
    case SET_FACET_OPTIONS: {
      const newState = _.setWith(_.clone(state), payload.name, payload.value, _.clone);
      return { ...state, ...newState };
    }
    // Payload: { name: 'solution' }
    case GET_FACET_PREVIEW_MATCHES: {
      const newState = _.setWith(_.clone(state), `${payload.name}.loadingPreviewMatches`, true, _.clone);
      return { ...state, ...newState };
    }
    // Payload: { name: 'solution', value: 50 }
    case SET_FACET_PREVIEW_MATCHES: {
      const newState = _.setWith(_.clone(state), `${payload.name}.loadingPreviewMatches`, false, _.clone);
      _.set(newState, `${payload.name}.previewMatches`, payload.value);
      return { ...state, ...newState };
    }
    case SET_APP_INITIALIZED: {
      return { ...state, initialized: true };
    }
    case RESET_APP_INITIALIZED: {
      return { ...state, initialized: false };
    }
    case SET_PROGRAM_TYPE: {
      return { ...state, programPath: payload.programPath };
    }
    case UPDATE_IS_MOBILE: {
      return { ...state, isMobile: payload.isMobile };
    }
    default:
      return state;
  }
};
