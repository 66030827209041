import _ from 'lodash';

import {
  ADD_SINGLE_FILTER,
  REMOVE_SINGLE_FILTER,
  CLEAR_ALL_FILTERS,
  SET_ALL_FILTERS,
  EXPAND_FILTERS,
  CONTRACT_FILTERS,
} from '../constants';

export const INITIAL_STATE = {
  expanded: false,
  selected: {
    locationType: 'all',
    specialized: true,
  },
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case EXPAND_FILTERS: {
      return {
        ...state,
        expanded: true,
      };
    }
    case CONTRACT_FILTERS: {
      return {
        ...state,
        expanded: false,
      };
    }
    case ADD_SINGLE_FILTER: {
      // Payload example: { name: 'location.geo', value: 'apac'}
      // { name: 'solution', value: ['Analytics', 'AdCloud'] }
      let stateClone = _.clone(state);
      if (payload.name === 'location') stateClone = _.unset(_.clone(stateClone), 'selected.country');
      const newState = _.setWith(stateClone, `selected.${payload.name}`, payload.value, _.clone);
      return { ...state, ...newState };
    }

    case REMOVE_SINGLE_FILTER: {
      const newAppliedFilters = { ...state.selected };
      delete newAppliedFilters[payload.name];
      return {
        ...state,
        selected: newAppliedFilters,
      };
    }

    case SET_ALL_FILTERS: {
      // Sets all filters at once, like from the query parameter or local storage.
      // Note: This overwrites all existing set filters.
      // Payload example: { location: {geo: 'apac'}, solution: ['Analytics', 'AdCloud'] }
      const newState = _.setWith(_.clone(state), 'selected', payload, _.clone);
      return { ...state, ...newState };
    }
    case CLEAR_ALL_FILTERS: {
      return {
        ...state,
        selected: {
          locationType: 'all',
        },
      };
    }
    default:
      return state;
  }
};
